import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  public constructor(private translateService: TranslateService) {}

  public async init(): Promise<void> {
    this.translateService.addLangs(["de"]);
    // const browser_language: string = this.getFirstBrowserLanguage();
    this.translateService.setDefaultLang("de");
    return this.translateService.use("de").toPromise();
  }

  public getFirstBrowserLanguage(): string {
    const nav = window.navigator,
      browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"];
    let len: number,
      shortLanguage = null;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
      for (const language of nav.languages) {
        len = language.length;
        if (!shortLanguage && len) {
          shortLanguage = language;
        }
        if (language && len > 2) {
          return language.substr(0, 2);
        }
      }
    }

    // support for other well known properties in browsers
    for (const language of browserLanguagePropertyKeys) {
      len = language.length;
      if (!shortLanguage && len) {
        shortLanguage = language;
      }
      if (language && len > 2) {
        return language.substr(0, 2);
      }
    }

    return shortLanguage;
  }
}
